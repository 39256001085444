import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/PhonIcon.svg";
import PostCallModal from './Modal/PostCallModal';
import StatusDropDown from '../../../components/Custom Table/TableColumns/StatusDropDown';
import { getSelectedValue } from '../../../utils/selectedOptionUtils';
import Consumer from '../../../helpers/context';
import RightModal from './Modal/RightModal';
import StatusDropdown from '../Components/StatusDropdown';
import TooltipText from '../../../components/Tooltip';
import { createDatewithTime } from '../../../helpers/date';
import DynamicIntent from '../../../components/Custom Table/TableColumns/DynamicIntent';
import RenderDashboardTableData from "../Components/RenderDashboardTableData";
import { ReactComponent as TableIcon } from '../../../assets/new/table.svg';
import PresaleColumnCustomisation from "./Modal/PresaleColumnCustomisation";
import { saveColumnPreferenceForOpportunityDashbaord } from "../services/dashboard.service";
import { toast } from "react-toastify";

const NewLeads = ({
  opportunityData,
  tableColumn,
  getOpportunities,
  sourceStatuses,
  statuses,
  intentStatuses,
  dashboardColumns,
  getColumns,
  updateColumnList,
  handleChangeSelection
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState("Gautam Singh");
  const [showPostCallModal, setShowPostCallModal] = useState(false);
  const [showCustomisedColumnModal, setShowCustomisedColumnModal] = useState(false);

  let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

  const opportunityTags = [
    {
      name: "Presales ($Name)",
      isActive: true,
      status: "Site Visit Scheduled",
      date: "12:30pm, 21 Sept'22",
    },
    {
      name: "CP ($Name)",
      isActive: false,
      status: "Site Visit Scheduled",
      date: "12:30pm, 21 Sept'22",
      taggingValidity: "Tagging Validity: 23rd Oct'23",
    },
  ];

  const managers = ["Gautam Singh", "Rahul Sharma", "Priya Kapoor"];
  const closingOptions = ["Gautam Singh", "Other Person"];

  const saveCurrentUserColumnPrefrence = () => {
    const preference = dashboardColumns.map(items => {
      items.columns.selected.forEach((item, index) => {
        item.placement = index + 1;
      })
      return {
        tab: items.tab,
        columns: [...items.columns.selected, ...items.columns.notSelected]
      }
    })
    saveColumnPreferenceForOpportunityDashbaord({ preference }).then(res => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getColumns();
        setShowCustomisedColumnModal(false);
        // getLeadsData();
      }
    }).catch(err => {
      console.log({ err })
    })
  }

  return (
    <>
      {/* <div className="flex items-center justify-center h-screen">
      <button
        onClick={() => setModalOpen(true)}
        className="px-4 py-2 text-white bg-blue-500 rounded-md"
      >
        Open Modal
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onAssign={() => alert("Tagging Assigned!")}
        data={{
          opportunities,
          selectedManager,
          managers,
          onManagerChange: setSelectedManager,
        }}
      />
    </div> */}

      <div class="relative overflow-auto !w-100 !border !rounded-lg h-[72vh]">
        <table class="min-w-full text-sm text-left ">
          <thead class="text-sm  text-[#344054]">
            <tr className="border-b-2">
              <th scope="col" class="!pl-3 !py-2 !sticky left-0 text-sm !text-black700 !font-normal inter ">
                S.No
              </th>
              {
                tableColumn?.map((item, index) => {
                  return (
                    <th
                      key={`${item.label}_${index}`}
                      className={`${item.label === 'Name' ? '!sticky !left-14' : ''} !pl-3 !py-2`}
                      scope="col"
                    >
                      <span className='flex items-center gap-1 text-sm !text-black700 !font-normal inter '>
                        {item.label}
                        {/* <SortIconDisplay sortCol={col} sort={sort} colName={item.label} /> */}
                      </span>
                    </th>
                  )
                })
              }
              <th scope="col" class="!pl-3 !py-2 !sticky !right-0">
                <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                  <button onClick={() => setShowCustomisedColumnModal(true)}>
                    <TableIcon />
                  </button>
                </TooltipText>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              opportunityData?.length > 0 && opportunityData.map((lead, index) => (
                <tr class=" border-b !px-3">
                  <td class="!pl-3 !py-2 bg-white !sticky left-0 text-[#44444F]">
                    {index + 1}

                  </td>
                  {
                    tableColumn?.map((item, index) => {
                      return (
                        <RenderDashboardTableData
                          tHead={item}
                          tData={lead}
                          isRedirectOnNameClick={false}
                          intentStatuses={intentStatuses}
                          statuses={statuses}
                          sourceStatuses={sourceStatuses}
                          redirectURL={'presale/opportunity-details'}
                          onSuccess={getOpportunities}
                        />
                      )
                    })}
                </tr>
              ))
            }

          </tbody>
        </table>
        {showPostCallModal && (
          <PostCallModal
            show={showPostCallModal}
            closeModal={() => setShowPostCallModal(false)}
          />
        )}
      </div>
      {isModalOpen && (
        <RightModal
          leadName="Sumit Singh"
          leadContact="+91-8797616890"
          projectName="Haridwar One"
          viewLeadLink="#"
          opportunityTags={opportunityTags}
          closingOptions={closingOptions}
          commentsPlaceholder="Add your comments here..."
          defaultComment="She’s interested in our new product line and wants our very best price please include a detailed breakdown of costs."
          onCancel={() => setModalOpen(false)}
          onAssignTagging={() => alert("Assign Tagging clicked")}
          onClose={() => setModalOpen(false)} // Close modal
        />
      )}
      {showCustomisedColumnModal &&
        <PresaleColumnCustomisation
          dashboardColumns={dashboardColumns}
          activeColumnTab='All'
          show={showCustomisedColumnModal}
          setShow={setShowCustomisedColumnModal}
          updateColumnList={updateColumnList}
          handleChangeSelection={handleChangeSelection}
          saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
        />
      }
    </>
  );
};

export default NewLeads;

import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import moment from 'moment';
import { enums } from '../../../helpers/filtersEnum';
import { InProgessReasonsList, NotConnectedReasonsList, SVCancelledReasonList, svTypes } from '../../../utils/constants';

const FiltersTag = ({ filters, removeFilterValue, propertyEnums, reasons, agencyList, csvList,
    project,
    intentStatuses,
    fbFormList,
    sourceStatuses,
    statuses,
    allUsers
}) => {

    const renderFilterList = (filterLists) => {
        if (enums[Object.keys(filterLists)[0]] === 'City') {
            return filterLists.City.map((item, index) => (
                <li key={`${item.label}_${index}`}>{item.label}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Intent') {
            return (
                intentStatuses.map(item => {
                    return filterLists.Intent.map((intent, index) => {
                        if (intent === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Status') {
            return (
                statuses.map(item => {
                    return filterLists.Status.map((status, index) => {
                        if (status === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Facebook Forms') {
            return (
                fbFormList.map(item => {
                    return filterLists['Form Id'].map((id, index) => {
                        if (id === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'SV Type') {
            return (
                svTypes.map(item => {
                    return filterLists.sv_type.map((type, index) => {
                        if (type === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'import_job_id') {
            return (
                csvList.map(item => {
                    return filterLists.import_job_id.map((jobId, index) => {
                        if (jobId === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Project') {
            return (
                project.map(item => {
                    return filterLists.Project.map((proj, index) => {
                        if (proj === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Source') {
            return (
                sourceStatuses.map(item => {
                    return filterLists.Source.map((src, index) => {
                        if (src === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Re-Engaged Source') {
            return (
                sourceStatuses.map(item => {
                    return filterLists.re_engage_sources.map((src, index) => {
                        if (src === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Purpose') {
            return (
                propertyEnums.enum_purpose.map(item => {
                    return filterLists.purpose.map((prps, index) => {
                        if (prps === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Funding Source') {
            return (
                propertyEnums.enum_funding_source.map(item => {
                    return filterLists.funding_source.map((src, index) => {
                        if (src === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Facing') {
            return (
                propertyEnums.enum_facing.map(item => {
                    return filterLists.facing.map((fc, index) => {
                        if (fc === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Possession Preference') {
            return (
                propertyEnums.enum_possession.map(item => {
                    return filterLists.possession.map((ps, index) => {
                        if (ps === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Industry') {
            return (
                propertyEnums.enum_industry.map(item => {
                    return filterLists.industry.map((ind, index) => {
                        if (ind === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Not Connected Reason') {
            return (
                NotConnectedReasonsList.map(item => {
                    return filterLists.not_connected_reason.map((ind, index) => {
                        if (ind === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'In Progress Reason') {
            return (
                InProgessReasonsList.map(item => {
                    return filterLists.in_progress_reason.map((ind, index) => {
                        if (ind === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'SV Cancellation Reason') {
            return (
                SVCancelledReasonList.map(item => {
                    return filterLists.sv_cancellation_reason.map((ind, index) => {
                        if (ind === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Requirement Type') {
            return (
                propertyEnums.enum_property_type.map(item => {
                    return filterLists.property_type.map((requirement, index) => {
                        if (requirement === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Employment Type') {
            return filterLists.employment_type.map((item, index) => (
                <li key={`${item}_${index}`}>{item}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Broker Company') {
            return filterLists.broker_company_id.map((item, index) => (
                <li key={`${item}_${index}`}>{item.name}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Customer Address') {
            return <li>{filterLists.customer_address}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Preferred Location') {
            return <li>{filterLists.preferred_location}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Referred By') {
            return <li>{filterLists.referred_by}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Broker Code') {
            return <li>{filterLists.broker_user_code}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Configuration Type') {
            return <li>{filterLists.configuration}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Property Type') {
            return (
                propertyEnums.enum_property_category.map(item => {
                    return filterLists.property_category.map((category, index) => {
                        if (category === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Dead Reasons') {
            return (
                reasons.map(item => {
                    return filterLists['Dead Reasons'].map((reason, index) => {
                        if (reason === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Followup Date') {
            return <li>Followup Date</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Booking Date') {
            return <li>Booking Date</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Eoi Date') {
            return <li>
                Eoi Date
            </li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'SV Schedule Date') {
            return <li>SV Schedule Date</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'SV Complete Date') {
            return <li>SV Complete Date</li>
        }
        if ((Object.keys(filterLists)[0] === 'Assigned To' && enums['Assigned User']) === 'Assigned User') {
            return filterLists['Assigned To'].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Created Date') {
            return <>
                <li className="mb-1">From: {moment(filterLists['Created Date'].From).format("Do MMM YYYY")} </li>
                <li>To:  {moment(filterLists['Created Date'].To).format("Do MMM YYYY")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Re assign Date') {
            return <>
                <li className="mb-1">From: {moment(filterLists['re_assign_date'].From).format("Do MMM YYYY")} </li>
                <li>To:  {moment(filterLists['re_assign_date'].To).format("Do MMM YYYY")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Re engage Date') {
            return <>
                <li className="mb-1">From: {moment(filterLists['re_engage_date'].From).format("Do MMM YYYY")} </li>
                <li>To:  {moment(filterLists['re_engage_date'].To).format("Do MMM YYYY")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Created Time') {
            return <>
                <li className="mb-1">From: {moment(filterLists['Created Time'].From).format("Do MMM YYYY, hh:mm a")} </li>
                <li>To:  {moment(filterLists['Created Time'].To).format("Do MMM YYYY, hh:mm a")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Assigned Date') {
            return <>
                <li className="mb-1">From: {moment(filterLists['Updated Date'].From).format("Do MMM YYYY")} </li>
                <li>To:  {moment(filterLists['Updated Date'].To).format("Do MMM YYYY")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Name') {
            return filterLists.Name.map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))

        }
        if (enums[Object.keys(filterLists)[0]] === 'Shared With') {
            return (
                allUsers.map(item => {
                    return filterLists.shared_with.map((shared, index) => {
                        if (shared === item.value) {
                            return <li key={`${item.label}_${index}`}>{item.label}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Marketing Agency') {
            return (
                agencyList.map(item => {
                    return filterLists['Marketing Agency'].map((agency, index) => {
                        if (agency === item.id) {
                            return <li key={`${item.label}_${index}`}>{item.name}</li>
                        }
                    })
                })
            )
        }
        if (enums[Object.keys(filterLists)[0]] === 'Mobile No') {
            return filterLists['Mobile No.'].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Size') {
            return <li>{filterLists.size.min}-{filterLists.size.max} {filterLists.size.unit.value}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Budget') {
            return <>
                {filterLists.budget?.min?.label && filterLists.budget?.max?.label && <li>{filterLists.budget.min.label} - {filterLists.budget.max.label}</li>}
                {filterLists.budget?.min?.label && !filterLists.budget?.max?.label && <li>{filterLists.budget.min.label}</li>}
                {!filterLists.budget?.min?.label && filterLists.budget?.max?.label && <li>{filterLists.budget.max.label}</li>}
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Email') {
            return <li>{filterLists.email}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Alt. Mobile') {
            return <li>{filterLists.alternate_mobile}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Re-Engaged') {
            return <li>{filterLists.is_re_engagement && 'Re-engaged'}</li>
        }

        if (enums[Object.keys(filterLists)[0]] === 'Site Visit Completed Leads') {
            return <li>{filterLists.is_site_visit_done && 'Site Visit Completed Leads'}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Hide Team Leads') {
            return <li>{filterLists.hide_team_leads && 'Hide Team Leads'}</li>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Marked Date') {
            return <>
                <li className="mb-1">From: {moment(filterLists['status_changed_on'].From).format("Do MMM YYYY, hh:mm a")} </li>
                <li>To:  {moment(filterLists['status_changed_on'].To).format("Do MMM YYYY, hh:mm a")}</li>
            </>
        }
        if (enums[Object.keys(filterLists)[0]] === 'Marked By') {
            return filterLists['status_changed_by'].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Token Cancellation') {
            return filterLists['eoi_cancellation_reason'].map((reason, index) => (
                <li key={`${reason}_${index}`}>{reason}</li>
            ))
        }
        if (enums[Object.keys(filterLists)[0]] === 'Booking Cancellation') {
            return filterLists['booking_cancellation_reason'].map((reason, index) => (
                <li key={`${reason}_${index}`}>{reason}</li>
            ))

        }

        if (enums[Object.keys(filterLists)[0]] === 'Sitevisit Confirmed') {
            return <li>{filterLists['is_confirmed'] ? 'Yes' : 'No'}</li>
        }

        // filterLists?.entries(([key, value]) => {
        //   console.log(key, value)
        // })
    }
    return (
        <div className="all-leads filter-applyed d-flex align-items-center">
            <span className="mt-0">Filtered by: &nbsp;</span>
            <ul className="tags align-items-center !pb-0 !pt-0" id="scroll-tag">
                <OwlCarousel
                    className='owl-theme'
                    dots={false}
                    res='true'
                    autoWidth='true'
                    loop={false}
                    navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                    margin={10}
                    responsive={{
                        0: {
                            items: 1.3,
                            nav: true,
                            margin: 0

                        },
                        600: {
                            items: 3,
                            nav: true
                        },
                        900: {
                            items: 4,
                            nav: true
                        },
                        1000: {
                            items: 5,
                            nav: true
                        },
                        1200: {
                            items: 6,
                            loop: false,
                            nav: true,
                            margin: 0

                        }
                    }
                    }>
                    {filters.map((data, index) => {
                        return (
                            <div className="item" key={String(index)}>
                                <li >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                                <div className='info-msg'>
                                                    <ul className="mb-0">
                                                        {renderFilterList(data)}
                                                        {/* <li>1. Signed up by mistake</li> */}
                                                        {/* <li>2. Signed up by mistake</li> */}
                                                    </ul>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <Button onClick={() => removeFilterValue(Object.keys(data))} className='bg-tr border-0 p-0' bsstyle="default" style={{ fontSize: 0 }}>
                                            <svg className="ml-0 mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.02344 5.07518C7.02344 5.33376 7.12616 5.58176 7.30901 5.76461C7.49186 5.94746 7.73985 6.05018 7.99844 6.05018C8.25702 6.05018 8.50502 5.94746 8.68787 5.76461C8.87071 5.58176 8.97344 5.33376 8.97344 5.07518C8.97344 4.81659 8.87071 4.5686 8.68787 4.38575C8.50502 4.2029 8.25702 4.10018 7.99844 4.10018C7.73985 4.10018 7.49186 4.2029 7.30901 4.38575C7.12616 4.5686 7.02344 4.81659 7.02344 5.07518ZM7.35884 11.3672C7.38597 11.517 7.46487 11.6526 7.58177 11.7503C7.69867 11.8479 7.84613 11.9014 7.99844 11.9014C8.15074 11.9014 8.29821 11.8479 8.41511 11.7503C8.532 11.6526 8.6109 11.517 8.63804 11.3672L8.64844 11.2502V8.00018L8.63804 7.88318C8.6109 7.73331 8.532 7.59773 8.41511 7.5001C8.29821 7.40247 8.15074 7.34899 7.99844 7.34899C7.84613 7.34899 7.69867 7.40247 7.58177 7.5001C7.46487 7.59773 7.38597 7.73331 7.35884 7.88318L7.34844 8.00018V11.2502L7.35884 11.3672Z" fill="#92929D" />
                                                <rect x="0.75" y="-0.75" width="11.5" height="11.5" rx="5.75" transform="matrix(1 0 0 -1 1.5 13)" stroke="#92929D" strokeWidth="1.5" />
                                            </svg>

                                        </Button>
                                    </OverlayTrigger>
                                    {Object.keys(data)[0] === 'Assigned To' ? enums['Assigned User'] : enums[Object.keys(data)]}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F"></path></svg>
                                </li>
                            </div>
                        )
                    })}

                </OwlCarousel>
            </ul>
        </div>
    )
}

export default FiltersTag
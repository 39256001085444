import React from 'react'

const PresaleDropdown = ({ scheduledBy, setScheduleBy, dropClass }) => {
    return (
        <div className={`dropdown view-more !px-3 !py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 !rounded-lg hover:bg-gray-50 flex items-center ${dropClass}`}>

            <button
                className="inline-flex items-center justify-center "
                type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >
                {scheduledBy === 'direct' ? 'Direct' : 'Channel Partner'}
                <svg
                    className="w-4 h-4 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div className="dropdown-menu dropdown-menu-right !p-2" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" onClick={() => {
                    setScheduleBy('direct')
                }}>
                    Direct
                </a>
                <a className="dropdown-item" onClick={() => {
                    setScheduleBy('cp')
                }}>
                    Channel Partner
                </a>
            </div>
        </div>
    )
}

export default PresaleDropdown
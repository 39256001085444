import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import CustomColumnTab from '../../../../components/customise-columns/CustomColumnTab';

const BookingTokenCoulmnCustomisation = ({
    show,
    setShow,
    updateColumnList,
    handleChangeSelection,
    saveCurrentUserColumnPrefrence,
    dashboardColumns,
    activeColumnTab
}) => {

    const [activeTab, setActiveTab] = useState('All');
    const [tabScrollPosition, setTabScrollPosition] = useState(0)

    // let { userProfile } = useContext(Consumer);
    console.log(activeTab, activeColumnTab, dashboardColumns)
    useEffect(() => {
        if (activeColumnTab)
            setActiveTab(activeColumnTab)
    }, [activeColumnTab]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            className="main-filter leadsDisplayColumns"
        // backdrop='static'
        // keyboard= {false}
        >
            <Modal.Body>
                <div className="modal-conents">
                    <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">

                        <h1 className='fz24 h-font fw-regular mb-0'>Customise Columns</h1>
                        {/* <p className='fz12 ro black-dark-700 mb4'>{subHeading}</p> */}
                        <a className='close' onClick={() => setShow(false)} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725" />
                        </svg>
                        </a>

                        <div className="display-col-tab-header">
                            {/* {tabScrollPosition > 0 && <button className='border-0 bg-tr btn-left d-md-inline d-none' onClick={scrollRight}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                            </button>}
                            {tabScrollPosition < 188 && <button className='border-0 bg-tr btn-right d-md-inline d-none' onClick={scrollLeft}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                            </button>}
                            <button className='border-0 bg-tr btn-left d-md-none' onClick={scrollRight}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                            </button>
                            <button className='border-0 bg-tr btn-right d-md-none' onClick={scrollLeft}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                            </button> */}

                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className='nav-item' onClick={() => setActiveTab('Token')}>
                                    <a className={`nav-link  ${activeTab === 'Token' ? 'active' : ''}`} >
                                        Token
                                    </a>
                                </li>
                                <li className='nav-item' onClick={() => setActiveTab('Booking')}>
                                    <a className={`nav-link  ${activeTab === 'Booking' ? 'active' : ''}`} >
                                        Booking
                                    </a>
                                </li>
                                <li className='nav-item' onClick={() => setActiveTab('Token Sv')}>
                                    <a className={`nav-link  ${activeTab === 'Token Sv' ? 'active' : ''}`} >
                                        Token Sv
                                    </a>
                                </li>
                                <li className='nav-item' onClick={() => setActiveTab('Booking Sv')}>
                                    <a className={`nav-link  ${activeTab === 'Booking Sv' ? 'active' : ''}`} >
                                        Booking Sv
                                    </a>
                                </li>
                                <li className='nav-item' onClick={() => setActiveTab('Dead')}>
                                    <a className={`nav-link  ${activeTab === 'Dead' ? 'active' : ''}`} >
                                        Dead
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    < div className='h-full overflow-auto'>
                        <div className="tab-content h-full [&>*]:h-full" id="pills-tabContent">
                            {activeTab === 'Token' && <div className={`tab-pane fade  ${activeTab === 'Token' ? 'show active' : ''}`} >
                                <CustomColumnTab
                                    activeColumn={dashboardColumns.find(item => item.tab === activeTab)}
                                    handleChangeSelection={handleChangeSelection}
                                    updateColumnList={updateColumnList}
                                />
                            </div>}
                            {activeTab === 'Booking' && <div className={`tab-pane fade  ${activeTab === 'Booking' ? 'show active' : ''}`} >
                                <CustomColumnTab
                                    activeColumn={dashboardColumns.find(item => item.tab === activeTab)}
                                    handleChangeSelection={handleChangeSelection}
                                    updateColumnList={updateColumnList}
                                />
                            </div>}
                            {activeTab === 'Token Sv' && <div className={`tab-pane fade  ${activeTab === 'Token Sv' ? 'show active' : ''}`} >
                                <CustomColumnTab
                                    activeColumn={dashboardColumns.find(item => item.tab === activeTab)}
                                    handleChangeSelection={handleChangeSelection}
                                    updateColumnList={updateColumnList}
                                />
                            </div>}
                            {activeTab === 'Booking Sv' && <div className={`tab-pane fade  ${activeTab === 'Booking Sv' ? 'show active' : ''}`} >
                                <CustomColumnTab
                                    activeColumn={dashboardColumns.find(item => item.tab === activeTab)}
                                    handleChangeSelection={handleChangeSelection}
                                    updateColumnList={updateColumnList}
                                />
                            </div>}
                            {activeTab === 'Dead' && <div className={`tab-pane fade  ${activeTab === 'Dead' ? 'show active' : ''}`} >
                                <CustomColumnTab
                                    activeColumn={dashboardColumns.find(item => item.tab === activeTab)}
                                    handleChangeSelection={handleChangeSelection}
                                    updateColumnList={updateColumnList}
                                />
                            </div>}
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={saveCurrentUserColumnPrefrence} >Save</button>
                    </div>
                </div>

            </Modal.Body >
        </Modal >
    )
}


export default BookingTokenCoulmnCustomisation
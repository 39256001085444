import React from 'react'

const AnotherBooking = () => {
    return (
        <div className='!pt-5 !px-6 pb-8 h-full overflow-auto flex justify-center'>

            <div className='flex items-center flex-col justify-center w-[400px]'>
                <p className='text-xl font-semibold !mb-3 text-center'>Keep the same information as Previous Booking?</p>
                <p className='text-sm text-grey-500'>You can edit the individual details later on.</p>
                <button className='bg-primary !rounded-lg !py-2.5 w-full text-white text-base font-medium'>
                    Continue with Same
                </button>
                <button className='text-base font-medium text-primary !mt-4'>Submit all last bookings</button>
            </div>
        </div>
    )
}

export default AnotherBooking
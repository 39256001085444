import React from "react";
import { ReactComponent as LeadIcon } from "../../../../assets/icons/LeadIcon.svg";
const RightModal = ({
  leadName,
  leadContact,
  projectName,
  viewLeadLink,
  opportunityTags,
  closingOptions,
  commentsPlaceholder,
  defaultComment,
  onCancel,
  onAssignTagging,
  onClose,
}) => {
  // if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 w-full h-full">
      <div className="fixed top-0 right-0 w-[600px] h-[100vh] bg-white shadow-lg rounded-l-lg border border-gray-200 p-3  z-50 ">
        {/* Close Button */}
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Header Section */}
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-semibold text-gray-900">
            Assign Tagging Issue
          </h1>
        </div>

        {/* Lead Information */}
        <div className="mt-3 flex justify-between items-start">
          <div>
            <p className="text-sm font-medium text-gray-900">{leadName}</p>
            <p className="text-sm text-gray-600">{leadContact}</p>
          </div>
          <div className="text-right">
            <p className="text-sm font-medium text-gray-900">{projectName}</p>
            <p className="text-sm text-gray-600">Project</p>
          </div>
          <div className="mt-2 flex justify-end items-start">
            <a
              href={viewLeadLink}
              className="text-sm font-medium text-blue-600 hover:underline mr-2"
            >
              View Lead
            </a>
            <LeadIcon className="w-5 h-5" />
          </div>
        </div>

        {/* Opportunity Section */}
        <div className="mt-3">
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Assign Opportunity tagging to{" "}
            <span className="text-red-500">*</span>
          </label>

          {opportunityTags.map((tag, index) => (
            <div
              key={index}
              className={`border rounded-lg p-2 mb-2 ${
                tag.isActive
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300 bg-gray-50"
              }`}
            >
              <div className="flex items-center border-b-2">
                <input
                  type="radio"
                  id={`tag-${index}`}
                  name="tagging"
                  className="w-4 h-4 mb-1 text-blue-500 border-gray-300 focus:ring-blue-500"
                  defaultChecked={tag.isActive}
                />
                <label
                  htmlFor={`tag-${index}`}
                  className="ml-3 mt-1 text-sm font-medium text-gray-900"
                >
                  {tag.name}
                </label>
                {tag.taggingValidity && (
                  <span className="ml-auto text-sm text-yellow-600">
                    {tag.taggingValidity}
                  </span>
                )}
                {tag.isActive && (
                  <span className="ml-auto text-sm text-gray-600 ">
                    Currently Tagged
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-600 mt-2">{tag.status}</p>
              <p className="text-sm text-gray-500">{tag.date}</p>
            </div>
          ))}
        </div>

        {/* Assign Closing Section */}
        <div className="mt-3 ">
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Assign Closing <span className="text-red-500">*</span>
          </label>
          <select className="w-full bg-gray border border-gray-300 rounded-md p-2 text-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500">
            {closingOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {/* Comments Section */}
        <div className="mt-3">
          <label className="block text-sm font-semibold text-gray-900 mb-2">
            Comments
          </label>
          <textarea
            rows="3"
            className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500"
            placeholder={commentsPlaceholder}
            defaultValue={defaultComment}
          />
        </div>

        {/* Footer Buttons */}
        <div className="mt-3 flex justify-between">
          <button
            className="w-[180px] border border-blue-500 text-blue-500 rounded-md py-2 text-sm font-medium hover:bg-blue-50"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="w-[180px] bg-blue-500 text-white rounded-md py-2 text-sm font-medium hover:bg-blue-600"
            onClick={onAssignTagging}
          >
            Assign Tagging
          </button>
        </div>
      </div>
    </div>
  );
};

export default RightModal;

import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../../components/HOC/Tabs";
import SearchBox from "../../../components/SearchBox";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/dashboard_refresh.svg";

import ProjectFilters from "../../LeadsManager/LeadsTopBar/component/ProjectFilters";
import InternalTabs from "../Tabs/InternalTabs";
import AllLeads from "./AllLeads";
import NewLeads from "./NewLeads";
import NotConnectedLeads from "./NotConnectedLeads";
import InProgressLeads from "./InProgressLeads";
import SiteVisitLeads from "./SiteVisitLeads";
import DeadLeads from "./DeadLead";
import CreateOpportunities from "./Modal/CreateOpportunities";
import { ReactComponent as PluswhiteIcon } from "../../../assets/icons/Pluswhite.svg";
import { ReactComponent as ExportIcon } from '../../../assets/icons/ExportIcon.svg'
import FilterModal from "../../../components/Modals/FilterModal/FilterModal";
import Consumer from "../../../helpers/context";
import { getCustomerEnumsCall } from "../../../services/private/customer.service";
import { getCompanyAgency, getUniqueFacebookForms, getUsersForSettings } from "../../../services/private/company.service";
import { customerDashboard, customerDashboardStats, getColumnPreferenceForOpportunityDashboard, opportunityDashboard, opportunityStats, saveColumnPreferenceForOpportunityDashbaord } from "../services/dashboard.service";
import LeadsFooterBar from "../../LeadsManager/LeadsFooterBar";
import { use } from "react";
import FiltersTag from "../Components/FiltersTag";
import { getDeadReasons } from "../../../services/public/public.service";
import { getAllJobs } from "../../../services/private/csv.service";

const tabList = [
    {
        name: "All",
        value: "all_leads",
    },
    {
        name: "New",
        value: "new_leads",
    },
    // {
    //     name: 'Attempts',
    //     value: 'attempt'
    // },
    {
        name: "Not Connected",
        value: "not_connected",
    },
    {
        name: "In Progress",
        value: "in_progress",
    },
    {
        name: "Site Visits",
        value: "site_visits",
    },
    {
        name: "Dead",
        value: "dead_leads",
    },
];

const InterTabList = [
    {
        name: "All",
        value: "all",
        count: 0,
    },
    {
        name: "FollowUp Pending",
        value: "follow_up_pending",
        count: 0,
    },
    {
        name: "FollowUp Overdue",
        value: "follow_up_overdue",
        count: 0,
    },
];
const InterSiteVisitList = [
    {
        name: "Scheduled",
        value: "scheduled",
        count: 0,
    },
    {
        name: "Cancelled",
        value: "cancelled",
        count: 0,
    },
    {
        name: "Completed",
        value: "completed",
        count: 0,
    },
];



const PresaleDashboard = () => {
    const [selectedTab, setSelectedTab] = useState({
        name: "All",
        value: "all_leads",
    });
    const [tabRowCount, setTabRowCount] = useState({});
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilter] = useState(false);
    const [showCreateOpportunityModal, setShowCreateOppotunityModal] = useState(false);
    const [fbFormList, setFbFormList] = useState([]);
    const [propertyEnums, setPropertyEnums] = useState([]);
    const [opportunityData, setOpportunityData] = useState([]);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [allLeadsColumn, setAllLeadsColumn] = useState([]);
    const [newLeadsColumn, setNewLeadsColumn] = useState([]);
    const [siteVisitColumn, setSiteVisitColumn] = useState([]);
    const [notConnectedColumn, setNotConnectedColumn] = useState([]);
    const [inProgressColumn, setInProgressColumn] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sourceStatuses, setSourceStatuses] = useState([]);
    const [eventStatus, setEventStatus] = useState('all');
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [svScheduleCount, setSvScheduleCount] = useState(0);
    const [svCompleteCount, setSvCompleteCount] = useState(0);
    const [tokenCount, setTokenCount] = useState(0);
    const [bookingCount, setBookingCount] = useState(0);
    const [reasons, setReasons] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [csvList, setCSVList] = useState([]);
    const [project, setProject] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: "All",
        value: "all_leads",
        count: 0,
    });

    let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

    const getFbFormData = async () => {
        const res = await getUniqueFacebookForms();
        if (res.data.status === 200) {
            const list = res.data.data.map((item) => ({
                ...item,
                label: item.form_name,
                value: item.form_id,
            }));
            setFbFormList(list);
        }
    };

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);
            setStatuses(companyConfig.Status);
            setSourceStatuses(companyConfig?.Source)
            setProject(companyConfig.Project);
        }
    }, [companyConfig]);

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                setAgencyList(res.data.data)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getAllDataJobs = () => {
        getAllJobs()
            .then((res) => {
                if (res.data.status === 200) {
                    // setMappedLeads(res.data.data)
                    const mappedData = res.data.data.map(item => ({ ...item, label: item.filename, value: item.job_id }))
                    setCSVList(mappedData)
                }
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        value: data.id
                    }
                })
                // setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setReasons(reasonsData);
            }
        })
    }

    useEffect(() => {
        getFbFormData();
        getDeadReasonsStatus();
        getAllDataJobs();
        getAgencyData();
        getAllUsers();
        const load = async () => {
            const res = await getCustomerEnumsCall();
            setPropertyEnums(res.data.data);
        };
        load();
    }, []);

    const getOpportunities = async () => {
        const filterResult = filters.reduce((acc, obj) => {
            // const value = Object.values(obj)[0]; // Extract the nested object
            return { ...acc, ...obj }; // Merge it into the accumulator
        }, {})
        const payload = {
            limit: showingResults,
            pageNumber: currentPage,
            filters: filterResult,
            sort: '',
            col: '',
            text: searchText,
            filterType: selectedTab?.value,
            site_visits_filter: ''
        }
        const res = await opportunityDashboard(payload);
        if (res.data.status === 200) {
            if (selectedTab.name === 'All') {
                const ids = res.data.data.allLeads.map(l => l.id)
                localStorage.setItem('leadIds', JSON.stringify(ids))
                setOpportunityData(res.data.data?.allLeads)
            } else if (selectedTab.name === 'New') {
                const ids = res.data.data.newLeads.map(l => l.id)
                localStorage.setItem('leadIds', JSON.stringify(ids))
                setOpportunityData(res.data.data?.newLeads)
            } else if (selectedTab?.name === 'Site Visits') {
                const ids = res.data.data.sitevisits.map(l => l.id)
                localStorage.setItem('leadIds', JSON.stringify(ids))
                setOpportunityData(res.data.data?.sitevisits)
            } else if (selectedTab?.name === 'Dead') {
                const ids = res.data.data.deadLeads.map(l => l.id)
                localStorage.setItem('leadIds', JSON.stringify(ids))
                setOpportunityData(res.data.data?.deadLeads)
            }
        }
    }

    const opportuniStats = async () => {
        const filterResult = filters.reduce((acc, obj) => {
            // const value = Object.values(obj)[0]; // Extract the nested object
            return { ...acc, ...obj }; // Merge it into the accumulator
        }, {})
        const payload = {
            limit: 200,
            pageNumber: 1,
            filters: filterResult,
            sort: '',
            col: '',
            text: searchText,
            filterType: selectedInternalTab?.value,
            site_visits_filter: ''
        }
        const res = await opportunityStats(payload)
        if (res.data.status === 200) {
            const stats = res.data.data;
            setTabRowCount({
                All: stats.allLeadsCount,
                New: stats.newLeadsCount,
                'Not Connected': 0,
                'In Progress': 0,
                'Site Visits': 0,
                Dead: stats.deadLeadsCount
            })
            setSvScheduleCount(stats?.siteVisitScheduledCount);
            setSvCompleteCount(stats?.siteVisitCompletedCount);
            setTokenCount(stats?.eoiOnlyCount)
            setBookingCount(stats?.bookingsOnlyCount)
            console.log(res.data.data, 'stats')
            if (selectedTab.name === 'All') {
                const totalPages = Math.ceil(
                    Number(stats?.allLeadsCount) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.allLeadsCount);
            }
            if (selectedTab.name === 'New') {
                const totalPages = Math.ceil(
                    Number(stats?.newLeadsCount) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.newLeadsCount);

            }

            if (selectedTab.name === 'Dead') {
                const totalPages = Math.ceil(
                    Number(stats?.deadLeadsCount) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.deadLeadsCount);
            }
            if (selectedTab.name === 'Site Visits') {
                const totalPages = Math.ceil(
                    Number(stats?.siteVisitScheduledCount) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.siteVisitScheduledCount);
            }
        }
    }

    const getColumns = async () => {
        const res = await getColumnPreferenceForOpportunityDashboard();
        if (res.data.status === 200) {
            let dashboardColumns = [];
            console.log(res.data.data, 'column')
            res.data.data.forEach(items => {
                let columnsList = [];
                let selectedColumn = [];

                items.columns.forEach(column => {
                    if (column.is_selected) {
                        selectedColumn.push(column);
                    }

                    if (!column.is_selected) {
                        columnsList.push(column);
                    }
                })

                // adding column according to tab with sorting
                dashboardColumns.push({
                    tab: items.tab,
                    columns: {
                        selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                        notSelected: columnsList
                    }
                })

                // data for table columns
                if (items.tab === 'New') {
                    setNewLeadsColumn(selectedColumn)
                }
                if (items.tab === 'Site Visits') {
                    setSiteVisitColumn(selectedColumn)
                }
                if (items.tab === 'All') {
                    setAllLeadsColumn(selectedColumn)
                }
                if (items.tab === 'Followups') {
                    setNotConnectedColumn(selectedColumn)
                }
            })
            setDashboardColumns(dashboardColumns)
        }
    }

    /**
   * Updating re-arranged list items
   */
    const updateColumnList = (tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    };

    const handleChangeSelection = (tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        setDashboardColumns([...columnClone]);
    };


    useEffect(() => {
        getOpportunities();
        opportuniStats()
    }, [selectedTab, searchText, filters, showingResults, currentPage]);

    useEffect(() => {
        setShowingResults(100);
        setCurrentPage(1);
    }, [selectedTab]);

    useEffect(() => {
        getColumns()
    }, []);

    const removeFilterValue = (keyValue) => {
        let oldFilters = filters;
        let newFilters = []
        for (const filterData of oldFilters) {
            // Check filter values and select element at pos 0 to compare with selected key
            if (!keyValue.includes(Object.keys(filterData)[0])) {
                newFilters.push(filterData);
            }
        }
        if (newFilters.length > 0) {
            localStorage.setItem('filter', JSON.stringify(newFilters))
        } else {
            localStorage.removeItem('filter')
        }
        setFilters(newFilters);
    }

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0 inter">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="w-100 d-flex justify-between flex-md-fill flex-wrap !mb-4">
                    <div className="flex items-center">
                        <div className="flex items-center flex-col md:flex-row">
                            <h1 className="text-2xl mb-0 black font-semibold !mr-1">
                                Presale Dashboard
                            </h1>
                            <button className="!border !p-1 rounded-lg hover:bg-grey-100" onClick={getOpportunities}>
                                <RefreshIcon />
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-end align-items-md-end aling-items-center flex-md-fill !space-x-4">
                        <SearchBox
                            searchText={searchText}
                            setSearchText={setSearchText}
                            placeholder={"Search"}
                            classname="mr-0"
                        />
                        <ProjectFilters handleFilter={setFilters} leadTopFilters={filters} cornerRadious={true} />
                        <button
                            className="bg-blue-600 text-white !pl-1 !pr-2 !py-1 font-medium flex items-center !rounded-lg hover:bg-blue-700  transition"
                            onClick={() => setShowCreateOppotunityModal(true)}
                        >
                            <PluswhiteIcon /> New Opportunity
                        </button>
                        <div className="filter-btn ">
                            <button
                                className={`d-flex align-items-center ${filters?.length > 0
                                    ? " border-0 pr-hover  active"
                                    : "border-0 btn"
                                    }`}
                                type="button"
                                onClick={() => setShowFilter(true)}
                            >
                                <svg
                                    className="mr-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z"
                                        fill="#696974"
                                    ></path>
                                </svg>
                                <span className="text-capitalize">
                                    Filter {filters?.length > 0 ? ` (${filters?.length})` : " "}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex w-100 justify-between">
                    <Tabs
                        tabList={tabList}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabName={selectedTab}
                        tabRowCount={tabRowCount}
                    />
                </div>
            </div>
            <div className="!pt-5 !px-5">
                <div className="flex items-center ">
                    <div className="w-[40%]">
                        {selectedTab.name === "All" && (
                            <InternalTabs
                                tabList={InterTabList}
                                selectedTab={selectedInternalTab}
                                setSelectedTab={setSelectedInternalTab}
                            />
                        )}
                        {/* {selectedTab.name === "New" && (
                    <InternalTabs
                        tabList={InterTabList}
                        selectedTab={selectedInternalTab}
                        setSelectedTab={setSelectedInternalTab}
                    />
                )} */}
                        {selectedTab.name === "Not Connected" && (
                            <InternalTabs
                                tabList={InterTabList}
                                selectedTab={selectedInternalTab}
                                setSelectedTab={setSelectedInternalTab}
                            />
                        )}
                        {selectedTab.name === "In Progress" && (
                            <InternalTabs
                                tabList={InterTabList}
                                selectedTab={selectedInternalTab}
                                setSelectedTab={setSelectedInternalTab}
                            />
                        )}
                        {selectedTab.name === "Site Visits" && (
                            <InternalTabs
                                tabList={InterSiteVisitList}
                                selectedTab={selectedInternalTab}
                                setSelectedTab={setSelectedInternalTab}
                            />
                        )}
                        {selectedTab.name === "Dead" && (
                            <InternalTabs
                                tabList={InterTabList}
                                selectedTab={selectedInternalTab}
                                setSelectedTab={setSelectedInternalTab}
                            />
                        )}
                    </div>
                    {filters.length > 0 &&
                        <div className="!mb-5">
                            <FiltersTag
                                filters={filters}
                                removeFilterValue={removeFilterValue}
                                reasons={reasons}
                                agencyList={agencyList}
                                propertyEnums={propertyEnums}
                                csvList={csvList}
                                project={project}
                                intentStatuses={intentStatuses}
                                fbFormList={fbFormList}
                                sourceStatuses={sourceStatuses}
                                statuses={statuses}
                                allUsers={allUsers}
                            />
                        </div>
                    }
                </div>
                {selectedTab.name === "All" && <AllLeads
                    opportunityData={opportunityData}
                    tableColumn={allLeadsColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
                {selectedTab.name === "New" && <NewLeads
                    opportunityData={opportunityData}
                    tableColumn={newLeadsColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
                {selectedTab.name === "Not Connected" && <NotConnectedLeads
                    opportunityData={opportunityData}
                    tableColumn={notConnectedColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
                {selectedTab.name === "In Progress" && <InProgressLeads
                    opportunityData={opportunityData}
                    tableColumn={notConnectedColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
                {selectedTab.name === "Site Visits" && <SiteVisitLeads
                    opportunityData={opportunityData}
                    tableColumn={siteVisitColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
                {selectedTab.name === "Dead" && <DeadLeads
                    opportunityData={opportunityData}
                    tableColumn={siteVisitColumn}
                    getOpportunities={getOpportunities}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    dashboardColumns={dashboardColumns}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                />}
            </div>
            <div className=" w-100 flex items-center justify-center space-x-3 absolute bottom-4 z-10">
                <div className="bg-black200 !px-1.5 !py-1 !rounded-md">
                    <p className="mb-0 text-xs ">
                        <span className="font-medium">SV Scheduled</span> - <span className="font-semibold">{svScheduleCount}</span>
                    </p>
                </div>
                <div className="bg-black200 !px-1.5 !py-1 !rounded-md">
                    <p className="mb-0 text-xs ">
                        <span className="font-medium">SV Completed</span> - <span className="font-semibold">{svCompleteCount}</span>
                    </p>
                </div>
                <div className="bg-black200 !px-1.5 !py-1 !rounded-md">
                    <p className="mb-0 text-xs ">
                        <span className="font-medium">Token</span> - <span className="font-semibold">{tokenCount}</span>
                    </p>
                </div>
                <div className="bg-black200 !px-1.5 !py-1 !rounded-md">
                    <p className="mb-0 text-xs ">
                        <span className="font-medium">Booking</span> - <span className="font-semibold">{bookingCount}</span>
                    </p>
                </div>
            </div>
            <LeadsFooterBar
                showingResults={showingResults}
                setShowingResults={setShowingResults}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={maxPage}
                leadsCount={leadsCount}
                classname='!relative !basis-0 !w-full'
            />
            {showCreateOpportunityModal && (
                <CreateOpportunities
                    show={showCreateOpportunityModal}
                    closeModal={() => setShowCreateOppotunityModal(false)}
                    getOpportunities={getOpportunities}
                    statuses={statuses}
                />
            )}
            {showFilters && (
                <FilterModal
                    show={showFilters}
                    setShow={setShowFilter}
                    handleFilter={setFilters}
                    setFilterCount={() => { }}
                    filtersData={filters}
                    leadType={""}
                    reAssignRole={""}
                    setCurrentPage={() => { }}
                    filterToaster={() => { }}
                    propertyEnums={propertyEnums}
                    isRolesModule={isRolesModule}
                    allowedPermissions={allowedPermissions}
                    fbFormList={fbFormList}
                />
            )}
        </div>
    );
};

export default PresaleDashboard;

import { API_METHOD } from '../../../config/apiUrl';
import requests from '../../../helpers/requests';
import { API_URLS_DASHBOARD } from './newDashboardAPIUrls';

export async function customerDashboardStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.customerDashboardStats}`, null, payload, null);
}

export async function customerDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.customerDashboard}`, null, payload, null);
}

export async function getTimeline(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getTimeline}/${uuid}`, null, payload, null);
}

export async function getCustomerOpportunities(uuid) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerOpportunities}/${uuid}`, null, null, null);
}

export async function getCustomerDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerDetails}/${uuid}`, null, null, null);
}

export async function getCustomerEventCount(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerEventCount}/${uuid}`, null, null, null);
}

export async function updateSingleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.updateSingleKeyValue}/${uuid}`, null, payload, null);
}

export async function updateDoubleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.updateDoubleKeyValue}/${uuid}`, null, payload, null);
}

// Column Customisation
export async function saveColumnPreferenceForcustomerDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForcustomerDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForCustomerDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForCustomerDashboard}`, null, null, null);
}

export async function saveColumnPreferenceForOpportunityDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForOpportunityDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForOpportunityDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForOpportunityDashboard}`, null, null, null);
}

export async function saveColumnPreferenceForBookingDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForBookingDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForBookingDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForBookingDashboard}`, null, null, null);
}

// Opportunity
export async function insertOpportunity(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.insertOpportunity}`, null, payload, null);
}

export async function opportunityDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityDashboard}`, null, payload, null);
}

export async function opportunityStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityStats}`, null, payload, null);
}

export async function changeStatus(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.changeStatus}/${uuid}`, null, payload, null);
}

export async function getOpportunityTimeline(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getTimeline}/${uuid}`, null, payload, null);
}

export async function getOpportunityBasicDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityBasicDetails}/${uuid}`, null, null, null);
}

export async function getOpportunityDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityDetails}/${uuid}`, null, null, null);
}
export async function getBookingAndEOIUnitsByOpportunityUUID(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getBookingAndEOIUnitsByOpportunityUUID}/${uuid}`, null, null, null);
}

export async function getOpportunityEventCount(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityEventCount}/${uuid}`, null, null, null);
}

export async function opportunityUpdateSingleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityUpdateSingleKeyValue}/${uuid}`, null, payload, null);
}

export async function opportunityUpdateDoubleKeyValue2(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityUpdateDoubleKeyValue2}/${uuid}`, null, payload, null);
}

// Site visit dashboard

export async function getSvForSVTracker(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getSvForSVTracker}/${uuid}`, null, payload, null);
}

export async function getStatsForSvTracker(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getStatsForSvTracker}/${uuid}`, null, payload, null);
}

export async function getDeadEventsAfterSv(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getDeadEventsAfterSv}/${uuid}`, null, payload, null);
}

// Booking/Token

export async function bookingDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.bookingDashboard}`, null, payload, null);
}

export async function bookingDashboardStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.bookingDashboardStats}`, null, payload, null);
}
import React, { useEffect } from 'react';

const InternalTabs = ({ selectedTab, setSelectedTab, tabList, tabName, tabRowCount }) => {

    return (
        <div className='w-100 tabs-bottom-active-border'>
            <div class=" border-0 !mb-2 gap-x-6" id="nav-tab" role="tablist">
                <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap" >
                    {tabList.map(item => (
                        <li className="" key={item.name}>
                            <a
                                className={`nav-link p-2 border-0 text-sm rounded-full relative  text-grey-500 hover:!bg-black200  ${selectedTab.name === item.name ? 'text-primary hover:text-primary !bg-primary/10' : 'text-[#667085] hover:text-[#667085]'}`}
                                onClick={() => setSelectedTab(item)}
                            >
                                {item.name + ` ${tabRowCount[item.name] ? '(' + tabRowCount[item.name] + ')' : ''}`}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default InternalTabs;
import React, { useEffect } from 'react';
import { numberFormat } from '../../../helpers/helpers';

const InternalTabs = ({ selectedTab, setSelectedTab, tabList }) => {

    return (
        <div className='w-100 tabs-bottom-active-border'>
            <div class=" border-0 !mb-5 gap-x-6" id="nav-tab" role="tablist">
                <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap" >
                    {tabList.map(item => (
                        <li className="" key={item.name}>
                            <a
                                className={`nav-link !py-1.5 !px-3 text-sm rounded-full relative font-medium hover:!text-primary bg-[#F1F1F6] hover:!bg-primary/10 hover:!border hover:!border-primary  ${selectedTab.name === item.name ? 'text-[#2064FA] hover:text-primary !bg-primary/10 !border !border-primary' : 'text-[#667085] hover:text-[#667085]'}`}
                                onClick={() => setSelectedTab(item)}
                            >
                                {item.name + ` - ${numberFormat(item.count)}`}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default InternalTabs;
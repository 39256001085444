import React, { useContext, useEffect, useState } from 'react'
import Tabs from '../../../components/HOC/Tabs'
import SearchBox from '../../../components/SearchBox';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/dashboard_refresh.svg'
import { ReactComponent as ExportIcon } from '../../../assets/icons/ExportIcon.svg'
import ProjectFilters from '../../LeadsManager/LeadsTopBar/component/ProjectFilters';
import InternalTabs from '../Tabs/InternalTabs';
import Booking from './Booking';
import BookingSV from './BookingSV';
import DeadLeads from './DeadLeads';
import Token from './Token';
import TokenSV from './TokenSV';
import { bookingDashboard, bookingDashboardStats, getColumnPreferenceForBookingDashboard, opportunityDashboard } from '../services/dashboard.service';
import Consumer from '../../../helpers/context';
import { useLocation } from 'react-router-dom';
import { ReactComponent as PluswhiteIcon } from "../../../assets/icons/Pluswhite.svg";
import LeadsFooterBar from '../../LeadsManager/LeadsFooterBar';
import PresaleDropdown from '../Components/PresaleDropdown';

const tabList = [
  {
    name: 'Token',
    value: 'token'
  },
  {
    name: 'Booking',
    value: 'booking'
  },
  {
    name: 'Token Sv',
    value: 'site_visits_token'
  },
  {
    name: 'Booking Sv',
    value: 'site_visits_booking'
  },
  {
    name: 'Dead',
    value: 'dead'
  },

]

const InterTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: ' Pending',
    value: 'pending',
    count: 0
  },
  {
    name: 'Approved',
    value: 'approved',
    count: 0
  },
  {
    name: 'Cancel',
    value: 'cancel',
    count: 0
  },

]

const TokenSVTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Token Sv Scheduled',
    value: 'sv_scheduled_token',
    count: 0
  },
  {
    name: 'Token Sv Completed',
    value: 'sv_completed_token',
    count: 0
  }
]

const BookingSVTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Booking Sv Scheduled',
    value: 'sv_scheduled_booking',
    count: 0
  },
  {
    name: 'Booking Sv Completed',
    value: 'sv_completed_booking',
    count: 0
  }
]

const DeadTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Post Token Dead',
    value: 'post_token_dead',
    count: 0
  },
  {
    name: 'Post Booking Dead',
    value: 'post_booking_dead',
    count: 0
  }
]

const tableColumn = [
  {
    label: 'Opportunities'
  },
  {
    label: 'Name'
  },
  {
    label: 'Mobile No.'
  },
  {
    label: 'Email'
  },
  {
    label: 'Status'
  },
  {
    label: 'Intent'
  },
  {
    label: 'Created Date'
  }
]

const keyMapping = {
  sv_completed_booking: 'booking_sv_completed',
  sv_scheduled_booking: 'booking_sv_scheduled',
  sv_completed_token: 'token_sv_completed',
  sv_scheduled_token: 'token_sv_scheduled',
  all: 'all',
  approved: 'approved',
  pending: 'pending',
  cancel: 'cancelled'
}
const BookingTokenDashboard = () => {
  const [selectedTab, setSelectedTab] = useState({
    name: 'Token',
    value: 'token'
  })
  const [tabRowCount, setTabRowCount] = useState({});
  const [active, setActive] = useState("By Current Active");
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilter] = useState(false);
  const [opportunityData, setOpportunityData] = useState([]);
  const [intentStatuses, setIntentStatuses] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sourceStatuses, setSourceStatuses] = useState([]);
  const [dashboardColumns, setDashboardColumns] = useState([]);
  const [showingResults, setShowingResults] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [leadsCount, setLeadsCount] = useState();
  const [eventStatus, setEventStatus] = useState('current');
  const [scheduledBy, setScheduleBy] = useState('direct');
  const [selectedInternalTab, setSelectedInternalTab] = useState({
    name: 'All',
    value: 'all',
    count: 0
  })
  const [isOpen, setIsOpen] = useState(false);
  let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getOpportunities = async () => {
    const payload = {
      perPage: showingResults,
      pageNumber: currentPage,
      filters: {},
      sortDirection: '',
      sortColumn: "",
      eventStatus: eventStatus,
      scheduledBy: scheduledBy,
      tab: selectedTab?.value,
      subTab: selectedInternalTab?.value,
      searchText: searchText,
    }
    const res = await bookingDashboard(payload);
    if (res.data.status === 200) {
      console.log(res.data.data, 'opportunities')
      setOpportunityData(res.data.data?.bookings)
      // if (selectedTab.name === 'Token') {
      // } else if (selectedTab.name === 'Booking') {
      //   setOpportunityData(res.data.data?.bookingOnly)
      // } else if (selectedTab?.name === 'Dead') {
      //   setOpportunityData(res.data.data?.deadLeads)
      // }
    }
  }

  const getStats = async () => {
    const payload = {
      perPage: showingResults,
      pageNumber: currentPage,
      filters: {},
      sortDirection: '',
      sortColumn: "",
      eventStatus: eventStatus,
      scheduledBy: scheduledBy,
      tab: selectedTab?.value,
      subTab: selectedInternalTab?.value,
      searchText: searchText,
    }
    const res = await bookingDashboardStats(payload);
    if (res.data.status === 200) {
      console.log(res.data.data, 'opportunities')
      const stats = res.data.data.stats;
      setTabRowCount({
        Token: stats?.token,
        Booking: stats?.booking,
        'Token Sv': stats?.tokenSv,
        'Booking Sv': stats?.bookingSv,
        Dead: stats?.dead
      })
      if (selectedTab.name === 'Token') {
        const totalPages = Math.ceil(
          Number(stats?.token) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.token);
        InterTabList.forEach(item => {
          item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab.name === 'Booking') {
        const totalPages = Math.ceil(
          Number(stats?.booking) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.booking);
        InterTabList.forEach(item => {
          item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab?.name === 'Token Sv') {
        const totalPages = Math.ceil(
          Number(stats?.tokenSv) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.tokenSv);
        TokenSVTabList.forEach(item => {
          item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab?.name === 'Booking Sv') {
        const totalPages = Math.ceil(
          Number(stats?.bookingSv) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.bookingSv);
        BookingSVTabList.forEach(item => {
          item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
    }
  }

  const getColumns = async () => {
    const res = await getColumnPreferenceForBookingDashboard();
    if (res.data.status === 200) {
      let dashboardColumns = [];
      console.log(res.data.data, 'column')
      res.data.data.forEach(items => {
        let columnsList = [];
        let selectedColumn = [];

        items.columns.forEach(column => {
          if (column.is_selected) {
            selectedColumn.push(column);
          }

          if (!column.is_selected) {
            columnsList.push(column);
          }
        })

        // adding column according to tab with sorting
        dashboardColumns.push({
          tab: items.tab,
          columns: {
            selected: selectedColumn.sort((a, b) => a.placement - b.placement),
            notSelected: columnsList
          }
        })

        // data for table columns
        // if (items.tab === 'New') {
        //     setNewLeadsColumn(selectedColumn)
        // }
        // if (items.tab === 'Site Visits') {
        //     setSiteVisitColumn(selectedColumn)
        // }
        // if (items.tab === 'All') {
        //     setAllLeadsColumn(selectedColumn)
        // }
        // if (items.tab === 'Followups') {
        //     setNotConnectedColumn(selectedColumn)
        // }
      })
      setDashboardColumns(dashboardColumns)
    }
  }


  /**
 * Updating re-arranged list items
 */
  const updateColumnList = (tab, list) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    const itemColumn = columnClone[index].columns
    columnClone[index].columns = { ...itemColumn, selected: list }
    setDashboardColumns([...columnClone]);
  };

  const handleChangeSelection = (tab, selected, notSelected) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    columnClone[index].columns = { selected, notSelected }
    setDashboardColumns([...columnClone]);
  };



  useEffect(() => {
    if (Object.keys(companyConfig).length > 0) {
      setIntentStatuses(companyConfig.Intent);
      setStatuses(companyConfig.Status);
      setSourceStatuses(companyConfig?.Source)
    }
  }, [companyConfig]);

  useEffect(() => {
    if (searchParams.get('tab')) {
      const seleted = tabList.find(i => i.name === searchParams.get('tab'))
      setSelectedTab(seleted);
    }
  }, [searchParams]);

  useEffect(() => {
    getColumns();
  }, [])

  useEffect(() => {
    getOpportunities();
    getStats();
  }, [selectedTab, searchText, showingResults, currentPage, eventStatus, scheduledBy]);

  useEffect(() => {
    setSelectedInternalTab(InterTabList[0])
    setShowingResults(100);
    setCurrentPage(1);
  }, [selectedTab])


  return (
    <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
      <div className="!px-5 !border-b !border-grayLight">
        <div className="w-100 d-flex justify-between flex-md-fill flex-wrap !mb-4">
          <div className="flex items-center">
            <div className='flex items-center flex-col md:flex-row'>
              <h1 className="text-2xl mb-0 black font-semibold !mr-1"> Booking-Token Dashboard </h1>
              <button className='!border !p-1 rounded-lg'>
                <RefreshIcon />
              </button>
            </div>
          </div>
          <div className="d-flex justify-end align-items-md-end aling-items-center flex-md-fill !space-x-4">


            <ProjectFilters
              handleFilter={() => { }}
              leadTopFilters={[]}
              cornerRadious={true}
            />

            {/* Presale */}
            <PresaleDropdown scheduledBy={scheduledBy} setScheduleBy={setScheduleBy} />

            <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search'} classname='mr-0' />
            <button
              className="bg-primary text-white !pl-2 !pr-3 !py-1 !rounded-lg flex items-center font-medium text-sm"
              onClick={() => alert('New Opportunity Clicked!')} >
              <PluswhiteIcon /> New Site Visit
            </button>
            <div className="filter-btn ">
              <button className={`d-flex align-items-center ${Object.keys(filters)?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setShowFilter(true)}>
                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                <span className="text-capitalize">Filter {Object.keys(filters)?.length > 0 ? ` (${Object.keys(filters)?.length})` : " "}</span>
              </button>
            </div>
          </div>
        </div>
        <div className='flex w-100 justify-between'>
          <Tabs
            tabList={tabList}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabName={selectedTab}
            tabRowCount={tabRowCount}
          />
        </div>
      </div>
      <div className='!pt-5 !px-5'>
        <div className='flex w-full justify-between items-center'>
          {selectedTab.name === 'Token' &&
            <InternalTabs
              tabList={InterTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Booking' &&
            <InternalTabs
              tabList={InterTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Dead' &&
            <InternalTabs
              tabList={DeadTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Booking Sv' &&
            <InternalTabs
              tabList={BookingSVTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Token Sv' &&
            <InternalTabs
              tabList={TokenSVTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          <div className="flex items-center !gap-4 !mb-5">
            {/* Button Group */}
            <div className="flex border border-gray-300 rounded-lg w-max">
              <button
                onClick={() => setEventStatus("current")}
                className={`flex gap-2 px-4 py-2 text-sm ${eventStatus === "current"
                  ? "text-blue-600 bg-blue-50 font-semibold"
                  : "text-gray-700 hover:bg-gray-100"
                  }`}
              >
                By Current Active
              </button>
              <button
                onClick={() => setEventStatus("all")}
                className={`px-4 py-2 text-sm  ${eventStatus === "all"
                  ? "text-blue-600 bg-blue-50 font-semibold"
                  : "text-gray-700 hover:bg-gray-100"
                  }`}
              >
                All Events
              </button>
            </div>

            {/* Export Button */}
            <button
              className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-100"
            >
              <ExportIcon className='mr-2' />
              Export
            </button>
          </div>
        </div>
        {
          selectedTab.name === 'Token' && <Token
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
          />
        }
        {
          selectedTab.name === 'Booking' && <Booking
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
          />
        }
        {
          selectedTab.name === 'Token Sv' && <TokenSV
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
          />
        }
        {
          selectedTab.name === 'Booking Sv' && <BookingSV
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
          />
        }
        {
          selectedTab.name === 'Dead' && <DeadLeads
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
          />
        }
      </div>
      <LeadsFooterBar
        showingResults={showingResults}
        setShowingResults={setShowingResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        maxPage={maxPage}
        leadsCount={leadsCount}
        classname='!relative !basis-0 !w-full'
      />
    </div>
  )
}

export default BookingTokenDashboard
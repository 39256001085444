import React, { useEffect, useState } from 'react'
import RightModal from '../../../../components/Modals/RightModal'
import { getProjectsForFilter } from '../../../../services/private/leads.service';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

const PostCallModal = ({ show, closeModal }) => {

    const [projectList, setProjectList] = useState([])
    const [projects, setProjects] = useState({});

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item) => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
            // setAllProject(mapped)
        }
    }
    useEffect(() => {
        getProjects()
    }, []);

    return (
        <RightModal closeModal={closeModal} show={show} modalClass={'!w-[400px]'}>
            <div className="modal-header sticky top-0 left-0 bg-white !px-5  !py-4 flex">
                <h1 className='text-black text-2xl inter m-0'>Post Call Details</h1>
                <button onClick={closeModal} className='modal-close'>
                    <CloseIcon />
                </button>
            </div>
            <div className="modal-body !px-5 h-5/6 overflow-y-auto !space-y-4">
                <div className='form-group'>
                    <label htmlFor="">Lead Name</label>
                    <p className='mb-0 text-base font-semibold text-black'>Akshat Malasi | +91-7689797970 </p>
                </div>
                <InputSelect
                    label={'Project'}
                    required={true}
                    options={projectList}
                    isMulti={true}
                    value={projects ? projects : ''}
                    onChange={(e) => setProjects(e)}
                />
                <InputSelect
                    name="status"
                    maxMenuHeight="150px"
                    options={[]}
                    placeholder="Select Status"
                    value={{}}
                    onChange={(e) => {
                        console.log(e)
                    }}
                    label='Status'
                    required={true} />

                <InputSelect
                    name="intent"
                    maxMenuHeight="150px"
                    options={[]}
                    placeholder="Select Intent"
                    value={{}}
                    onChange={(e) => {
                        console.log(e)
                    }}
                    label='Intent'
                    required={true} />
                <div className='flex flex-col !mt-4 !mb-2'>
                    <label className='m-0'>Note</label>
                    <textarea
                        className="form-control mb-16"
                        name="remarks"
                        value={''}
                        placeholder="Add notes here"
                        onChange={() => { }}
                    >

                    </textarea>
                </div>

            </div>

            <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={closeModal} className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                <button onClick={() => {

                }} className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'> Add Opportunity</button>

            </div >
        </RightModal>
    )
}

export default PostCallModal
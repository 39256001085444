
export const API_URLS_DASHBOARD = {
    baseurl: process.env.REACT_APP_DEV_API_URL_V2,

    company: {
        config: '/companies/config',
        getColumnPreferenceForCustomerDashboard: '/companies/getColumnPreferenceForCustomerDashboard',
        saveColumnPreferenceForcustomerDashbaord: '/companies/saveColumnPreferenceForcustomerDashbaord',
        getColumnPreferenceForOpportunityDashboard: '/companies/getColumnPreferenceForOpportunityDashboard',
        saveColumnPreferenceForOpportunityDashbaord: '/companies/saveColumnPreferenceForOpportunityDashbaord',
        getColumnPreferenceForBookingDashboard: '/companies/getColumnPreferenceForBookingDashboard',
        saveColumnPreferenceForBookingDashbaord: '/companies/saveColumnPreferenceForBookingDashbaord'
    },
    dashboard: {
        customerDashboardStats: '/customer/customerDashboardStats',
        customerDashboard: '/customer/customerDashboard',
        getTimeline: '/customer/getTimeline',
        getCustomerOpportunities: '/customer/getCustomerOpportunities',
        getCustomerDetails: '/customer/getCustomerDetails',
        getCustomerEventCount: '/customer/getCustomerEventCount',
        updateSingleKeyValue: '/customer/updateByKeyValue',
        updateDoubleKeyValue: '/customer/updateByKeyValue2'

    },
    opportunity: {
        insertOpportunity: '/Opportunity/insertOpportunity',
        opportunityDashboard: '/Opportunity/opportunityDashboard',
        opportunityStats: '/Opportunity/opportunityStats',
        changeStatus: '/Opportunity/changeStatusV2',
        getTimeline: '/opportunity/getTimeline',
        getOpportunityBasicDetails: '/opportunity/getOpportunityBasicDetails',
        getOpportunityDetails: '/opportunity/getOpportunityDetails',
        getBookingAndEOIUnitsByOpportunityUUID: '/opportunity/getBookingAndEOIUnitsByOpportunityUUID',
        getOpportunityEventCount: '/opportunity/getOpportunityEventCount',
        opportunityUpdateDoubleKeyValue2: '/opportunity/updateByKeyValue2',
        opportunityUpdateSingleKeyValue: '/opportunity/updateByKeyValue',
        reassignSingleOpportunity: '/Opportunity/reassignSingleOpportunity'
    },
    booking: {
        bookingDashboard: '/bookingDone/bookingDashboard',
        bookingDashboardStats: '/bookingDone/bookingDashboardStats'
    },
    sitevisit: {
        getSvForSVTracker: '/siteVisit/getSvForSVTracker',
        getStatsForSvTracker: '/siteVisit/getStatsForSvTracker',
        getDeadEventsAfterSv: '/siteVisit/getDeadEventsAfterSv'
    },

}

// /
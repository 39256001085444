import React, { useContext, useEffect, useMemo, useState } from 'react'
import RightModal from '../../../../components/Modals/RightModal'
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import { getProjectsForFilter, reassignLeads } from '../../../../services/private/leads.service';
import TooltipText from '../../../../components/Tooltip';
import Consumer from '../../../../helpers/context';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../../services/private/company.service';
import { checkIsSomeItemAvailableInList } from '../../../../helpers/helpers';
import moment from 'moment';
import InputText from '../../../../components/InputGroup/InputText';
import { validityUnit } from '../../../../helpers/enums';
import { ReactComponent as PlusPrimaryIcon } from '../../../../assets/icons/PlusPrimary.svg';
import { toast } from 'react-toastify';
import MobileInput from '../../../../components/InputGroup/MobileInput';
import { customerDashboard, insertOpportunity } from '../../services/dashboard.service';
import { Oval } from 'react-loader-spinner';
import AsyncSelect from 'react-select/async';
import { InProgessReasonsList, NotConnectedReasonsList } from '../../../../utils/constants';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: '#44444f',
        zIndex: 99,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const CreateOpportunities = ({ closeModal, show, leadId, statusId, onSuccess, reassindedUserUuid, getOpportunities, statuses }) => {
    const [sameTime, setSameTime] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [projects, setProjects] = useState([]);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('')

    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState();
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [remarks, setRemarks] = useState('')
    const [users, setUsers] = useState([
        {
            access: 'edit',
            validity: 30,
            validity_unit: 'days'
        }
    ]);
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [customer, setCustomer] = useState('');
    const [source, setSource] = useState('');
    const [sourceList, setSourceList] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([])
    const [referredName, setReferredName] = useState('');
    const [referredMobile, setReferredMobile] = useState('');
    const [referredBySociety, setReferredBySociety] = useState('')
    const [referredFlatNo, setReferredFlatNo] = useState('');
    const [loading, setLoading] = useState(false)
    const [followupDate, setFollowupDate] = useState('');
    const [intentId, setIntentId] = useState('');
    const [closingManager, setClosingManager] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusReason, setStatusReason] = useState('');
    const [statusList, setStatusList] = useState([]);

    const { userProfile, allowedPermissions, companyConfig } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item) => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
            // setAllProject(mapped)
        }
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(leadId);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(reassindedUserUuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])

    useEffect(() => {
        const list = statuses.filter(s => (s.label === 'Not Connected' || s.label === 'In Progress'))
        setStatusList(list)
    }, [statuses])
    useEffect(() => {
        if (leadId) {
            // getActiveFollowup(leadId)
            setSelectedLeadId(leadId)
            if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                getShareLeadAccess()
            }
        }
        if (statusId && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessUsingStatusId();
            getShareLeadAccessAssignedToByStatus();
        }
        getAllUsers();
    }, [leadId, statusId]);

    useEffect(() => {
        if (companyConfig) {
            setSourceList(companyConfig?.Source)
            setIntentStatuses(companyConfig?.Intent)
        }
    }, [companyConfig])

    useEffect(() => {
        getProjects();
    }, []);

    const isDisable = useMemo(() => {
        if (ROLE_NAME === 'Super Admin') {
            return false;
        }

        return !allowedPermissions.includes('edit_lead_sharing')

    }, [allowedPermissions, ROLE_NAME]);

    console.log(customer, 'customer')
    const handleSubmit = async () => {

        if (projects.length === 0 || !customer?.value || (customer?.value === 'new' && (!name || !mobile)) || !statusReason || !statusReason || !source || !assignedTo) {
            toast.error('Please fill required fields');
            return;
        }
        if (projects.length > 0) {
            let empty = projects.some(p => (!p.followupScheduleDate || !p.intentId))
            if (empty) {
                toast.error('Please fill required fields');
                return;
            }
        }
        const payload = {
            customer_uuid: customer?.value !== 'new' ? customer?.value : '',
            name: name,
            mobile: mobile,
            sourceId: source,
            preSaleUserId: assignedTo,
            sourcingUserId: null,
            closingUserId: closingManager,
            remarks: remarks,
            referredBy: referredName,
            referredByNumber: referredMobile,
            referredBySociety: referredBySociety,
            referredByFlatNo: referredFlatNo,
            statusId: selectedStatus?.value,
            info: statusReason?.value,
            projects: projects.map(i => ({
                projectId: i.value,
                followupScheduleDate: sameTime ? followupDate : i.followupScheduleDate,
                intentId: sameTime ? intentId : i.intentId,
            }))
        }
        console.log(payload)
        try {
            setLoading(true);
            const res = await insertOpportunity(payload);
            setLoading(false);
            if (res.data.status === 200) {
                toast.success(res.data.message)
                getOpportunities();
                closeModal();
            }
        } catch (err) {
            toast.success(err.response.data.message)
            setLoading(false);
        }
    }

    const loadOptions = async (search) => {
        const payload = {
            "pageNumber": 1,
            "perPage": 100,
            "searchText": search,
            filters: {}
        }
        const res = await customerDashboard(payload)
        if (res.data.status === 200) {
            console.log(res.data.data, 'response')
            const users = res.data?.data.map((item) => {
                return {
                    label: <div className='flex flex-col text-sm !border-b !pb-1'> <span className='font-semibold text-black-t'>{item.name}</span> <span className='text-grey-500 text-xs'>{item.mobile}</span> </div>,
                    value: item.uuid
                }
            });
            return [{
                label: <div className='flex flex-col text-sm !border-b font-semibold text-[#1570EF] !pb-2'> New Customer</div>,
                value: 'new'
            }, ...users];
        }
    }

    return (
        <RightModal closeModal={closeModal} show={show} modalClass={'!w-[400px]'}>
            <div className="modal-header sticky top-0 left-0 bg-white !px-5  !py-4 flex">
                <h1 className='text-black text-2xl inter m-0'>New Opportunity</h1>
                <button onClick={closeModal} className='modal-close'>
                    <CloseIcon />
                </button>
            </div>
            <div className="modal-body !px-5 h-5/6 overflow-y-auto !space-y-4">
                <div>
                    <label htmlFor="">Customer</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        styles={customStyles}
                        placeholder='Search and Select'
                        value={customer}
                        onChange={(e) => {
                            setCustomer(e)
                        }}
                        isClearable={true}
                        menuPosition={'fixed'}
                    />
                </div>
                {/* <InputSelect
                    label={'Customer'}
                    required={true}
                    options={[]}
                    isMulti={false}
                    value={customer}
                    onChange={(e) => setCustomer(e)}
                /> */}
                {customer?.value === 'new' && <>
                    <InputText
                        label={'Customer Name'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <MobileInput
                        label={'Mobile'}
                        value={mobile}
                        onChange={(e) => setMobile(e.phone)}
                    />
                </>}
                <InputSelect
                    label={'Project'}
                    required={true}
                    options={projectList}
                    isMulti={true}
                    value={projects}
                    onChange={(e) => setProjects(e)}
                />
                {projects?.length > 1 && <div className='flex justify-between items-center'>
                    <label htmlFor="" className='mb-0 text-sm text-black-t font-medium'>Schedule all Followups at the same time</label>
                    <button
                        type="button"
                        className={`btn btn-lg btn-toggle ${sameTime ? 'active' : ''} mr-2`}
                        value={sameTime}
                        onClick={() => {
                            setSameTime(!sameTime)
                        }}
                    >
                        <div className="handle"></div>
                        <span className={`${sameTime ? 'on' : 'off'}`}></span>
                    </button>
                </div>}
                {
                    !sameTime && <>
                        {projects?.length > 0 && projects.map((item, index) => (
                            <div className='!space-y-4'>
                                {projects?.length > 1 && <p className='m-0 text-black-t text-sm font-medium flex items-center'>
                                    {
                                        item.label.length > 15 ? <TooltipText title={item.label}>
                                            <span className='!max-w-[150px] !min-w-[50px] text-nowrap truncate'>{item.label}</span>
                                        </TooltipText>
                                            :
                                            <span className='!max-w-[150px] !min-w-[50px] text-nowrap'>{item.label}</span>
                                    }
                                    <span className='!border-t w-[75%] !border-black-t inline-block !ml-3'></span>
                                </p>}
                                <DateTimePicker
                                    onValueChange={(date) => {
                                        let proj = [...projects]
                                        proj[index]['followupScheduleDate'] = date
                                        setProjects(proj)
                                    }}
                                    label="Select Followup Date & Time"
                                    value={projects[index]?.followupScheduleDate}
                                    name="scheduleDate"
                                    required={true}
                                />
                                <InputSelect
                                    name="intent"
                                    maxMenuHeight="150px"
                                    options={intentStatuses}
                                    placeholder="Select Intent"
                                    value={projects[index]?.intentId ? intentStatuses.find(i => i.value === projects[index]?.intentId) : ''}
                                    onChange={(e) => {
                                        let proj = [...projects]
                                        proj[index]['intentId'] = e.value
                                        setProjects(proj)
                                    }}
                                    label='Intent'
                                    required={true}
                                />

                            </div>
                        ))}
                        {/* {
                            projects?.length <= 1 && <>
                                <DateTimePicker
                                    label="Select Followup Date & Time"
                                    value={projects[0]?.followupScheduleDate}
                                    onValueChange={(date) => {
                                        let proj = [...projects]
                                        proj[0]['followupScheduleDate'] = date
                                        setProjects(proj)
                                    }}
                                    name="scheduleDate"
                                    required={true}
                                />
                                <InputSelect
                                    name="intent"
                                    maxMenuHeight="150px"
                                    options={intentStatuses}
                                    placeholder="Select Intent"
                                    value={projects[0]?.intentId ? intentStatuses?.find(i => i.value === projects[0]?.intentId) : ''}
                                    onChange={(e) => {
                                        let proj = [...projects]
                                        proj[0]['intentId'] = e.value
                                        setProjects(proj)
                                    }}
                                    label='Intent'
                                    required={true}
                                /> </>} */}
                    </>
                }
                {
                    sameTime && <>
                        <DateTimePicker
                            label="Select Followup Date & Time"
                            value={followupDate}
                            onValueChange={(date) => {
                                setFollowupDate(date)
                            }}
                            name="scheduleDate"
                            required={true}
                        />
                        <InputSelect
                            name="intent"
                            maxMenuHeight="150px"
                            options={intentStatuses}
                            placeholder="Select Intent"
                            value={intentId ? intentStatuses?.find(i => i.value === intentId) : ''}
                            onChange={(e) => {
                                setIntentId(e.value)
                            }}
                            label='Intent'
                            required={true}
                        />
                    </>
                }
                <InputSelect
                    name="status"
                    maxMenuHeight="150px"
                    options={statusList}
                    placeholder="Select Status"
                    value={selectedStatus}
                    onChange={(e) => {
                        setSelectedStatus(e)
                    }}
                    label='Status'
                    required={true}
                />
                <InputSelect
                    name="reason"
                    options={selectedStatus.label === 'Not Connected' ? NotConnectedReasonsList : InProgessReasonsList}
                    placeholder="Select Reason"
                    value={statusReason}
                    onChange={(e) => {
                        setStatusReason(e);
                    }}
                    label='Reason'
                    required={true}
                />
                <InputSelect
                    label={'Source'}
                    value={source ? sourceList.find(i => i.value === source) : ''}
                    onChange={(e) => setSource(e.value)}
                    options={sourceList}
                    required={true}
                />
                <div className='flex flex-col !mt-4 !mb-2'>
                    <label className='m-0'>Comments (optional)</label>
                    <textarea
                        className="form-control mb-16"
                        name="remarks"
                        value={remarks}
                        placeholder="Add notes here"
                        onChange={(e) => setRemarks(e.target.value)}
                    >

                    </textarea>
                </div>
                {userProfile?.allowed_modules?.includes('lead_sharing') &&
                    checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                    <div className='pt-3'>
                        <div className='!mt-4'>
                            <InputSelect
                                label={'Pre-sale Manager'}
                                placeholder={'Select User'}
                                options={usersForAssigned}
                                value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    setAssignedTo(value.value);
                                }}
                                disable={isDisable}
                                required={true}
                            />
                            <InputSelect
                                label={'Closing Manager'}
                                placeholder={'Select User'}
                                inputclass={'!mt-4'}
                                options={usersForAssigned}
                                value={closingManager ? usersForAssigned.find(user => user.value === closingManager) : ''}
                                onChange={(value) => {
                                    setClosingManager(value.value);
                                }}
                                disable={isDisable}
                            />
                        </div>
                        {/* <label className='!mt-4'>Shared With</label>
                        {
                            users.map((item, index) => (
                                <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                    <div className='flex justify-between items-center'>
                                        <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                        {allowedPermissions.includes('edit_lead_sharing') && <button
                                            type='button'
                                            className='!text-primary text-base font-medium'
                                            onClick={() => {
                                                let newUsers = [...users];
                                                newUsers.splice(index, 1);
                                                if (users.length === 1) {
                                                    setUsers([{}])
                                                } else {
                                                    setUsers([...newUsers])
                                                }
                                            }}
                                        >Remove</button>}
                                    </div>
                                    <div>
                                        <InputSelect
                                            placeholder={'Select User'}
                                            options={allUsers}
                                            value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                            onChange={(value) => {
                                                let newUsers = [...users];
                                                newUsers[index].user_id = value.value;
                                                // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                // setAllUsers(filteredUser)
                                                setUsers([...newUsers]);
                                            }}
                                            disable={isDisable}
                                        />
                                        <div className='mt-3'>
                                            <label htmlFor={`edit_${index}`} className='mr-4'>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`edit_${index}`}
                                                    value='edit'
                                                    checked={item.access === 'edit'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'edit'
                                                        setUsers(newUsers);
                                                    }}
                                                    disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                                <span>Edit Access</span>
                                            </label>
                                            <label htmlFor={`view_${index}`}>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`view_${index}`}
                                                    value='view'
                                                    checked={item.access === 'view'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'view'
                                                        setUsers(newUsers);
                                                    }}
                                                    disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                                <span>View Only</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="">Validity</label>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <InputText
                                                    placeholder={'e.g. 30'}
                                                    value={item.validity}
                                                    onChange={(e) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity = e.target.value
                                                        setUsers(newUsers);
                                                    }}
                                                    disable={isDisable}
                                                />
                                                <InputSelect
                                                    inputclass={'m-0'}
                                                    placeholder={'Select Time'}
                                                    value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                    options={validityUnit}
                                                    onChange={(value) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity_unit = value.value;
                                                        setUsers(newUsers);
                                                    }}
                                                    disable={isDisable}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <button
                            type='button'
                            className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold disabled:bg-transparent disabled:hover:bg-transparent'
                            onClick={() => setUsers([...users, {
                                access: 'edit',
                                validity: 30,
                                validity_unit: 'days'
                            }])}
                            disabled={isDisable}
                        >
                            <PlusPrimaryIcon />Add another
                        </button> */}
                    </div>}
                {
                    source === 852 && <>
                        <InputText
                            label={'Referred Name'}
                            placeholder={'Enter Referred Name'}
                            value={referredName}
                            onChange={(e) => setReferredName(e.target.value)}
                        />
                        <MobileInput
                            label={'Referred Mobile'}
                            value={referredMobile}
                            onChange={(e) => setReferredMobile(e.phone)}
                        />
                        <InputText
                            label={'Referred Society'}
                            placeholder={'Enter Referred Society'}
                            value={referredBySociety}
                            onChange={(e) => setReferredBySociety(e.target.value)}
                        />
                        <InputText
                            label={'Referred Flat No'}
                            placeholder={'Enter Referred Flat No'}
                            value={referredFlatNo}
                            onChange={(e) => setReferredFlatNo(e.target.value)}
                        />
                    </>
                }

            </div>

            <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={closeModal} className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                {!loading && <button onClick={handleSubmit} className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'> Add Opportunity</button>}
                {loading && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16  flex items-center justify-center'>
                    <Oval
                        height={24}
                        width={24}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}

            </div >
        </RightModal>
    )
}

export default CreateOpportunities